#forth {
    color: #adbdcc;
    background-color: #0a2540;
    position: relative;
    letter-spacing: .2px;
    font-size: 1.6rem;
}

#forthUpperWrapper {
    display: flex;
    padding-top: 10%;
    padding-bottom: 5%;
}

#forthUpperLeftWrapper {
    text-align: left;
    width: 40%;
    margin-left: 10%;
}

#forth h1 {
    color: orange;
}

#forthUpperRightWrapper {
    width: 40%;
    margin-right: 10%;
    display: flex;
    justify-content: center;
}

#forth td, #forth th {
    padding: 5px;
    color: black;
    border: 2px solid orange;
    border-radius: 2px;
    font-family: "noticia-text", "Cambria", "Hoefler Text", "Liberation Serif", "Times", "Times New Roman", "serif";
}

#forth .table-row-name, #forth th {
    font-size: 1.05rem;
    background-color: #E17E43;
    color: white;
    padding: 1%;
}

#forth th {
    font-weight: bold;
    font-size: 1.15rem;
}

#forth td {
    background-color: white;
}

#forthMiddleWrapper ol {
    text-align: left;
    margin: 5%;
}

#forthBottomWrapper {
    margin-right: 10%;
    text-align: right;
}

#forthBottomWrapperLower {
    display: flex;
    justify-content: space-between;
}

#forthBottomWrapperLeft {
    width: 60%;
}

#forthBottomWrapperLeft img {
    height: 90%;
    border-radius: 20px;
    box-shadow: rgba(240, 46, 170, 0.4) -5px 5px, rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px, rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px;
}

#forthBottomWrapperRight {
    width: 40%;
}

#forthBotthWrapperRight li {
    list-style-type: none;
}

@media only screen and (max-width: 900px) {
    #forthUpperWrapper {
        flex-direction: column;
    }

    #forthUpperRightWrapper {
        width: 80%;
        margin: 5%;
        text-align: center;
    }

    #forthUpperLeftWrapper {
        width: 80%;
    }
}
