#imageGallery {
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 80vh;
    border: 2px solid orange;
    border-radius: 50px 0px 0px 50px;
    background-color: white;
}

#closeButton {
    margin: 2%;
    position: absolute;
    right: 0;
    font-size: 2rem;
    background-color: orange;
    color: white;
}

#closeButton:active {
    transform: scale(.8);
    color: black;
}

#images {
    height: 80vh;
    border-radius: 5%;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: center;
}

#images a {
    margin: 2%;
    padding: 5px;
    border: 1px solid black;
    cursor: pointer;
}

#images img {
    width: 200px;
    height: 200px;
}