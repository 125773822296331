#tertiary {
    background-image: url("./Background.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1000;
    margin: 0;
    padding: 0;
}

#tertiaryWrapper {
    height: 120vh;
    padding-top: 20%;
    display: flex;
    justify-content: center;
}

#tertiary h1 {
    margin: 2%;
    font-size: 3.2rem;
    color: orange;
}

#tertiary #engineComparisonsTable {
    width: 70%;
}

#engineComparisonsTable table {
    margin: 0 auto;
}

#tertiary tbody {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    border-radius: 20px;
}

#tertiary td, #tertiary th {
    padding: 5px;
    border: 1px solid black;
    border-radius: 2px;
    font-family: "noticia-text", "Cambria", "Hoefler Text", "Liberation Serif", "Times", "Times New Roman", "serif";
}

#tertiary td img {
    width: 100px;
    height: 100px;
}

#tertiary .table-row-name, #tertiary th {
    font-size: 1.05rem;
    background-color: #E17E43;
    color: white;
    padding: 1%;
}

#tertiary th {
    font-weight: bold;
    font-size: 1.15rem;
}

#tertiary .table-horsepower {
    background-color: rgb(250, 237, 58);
} 

#tertiary tr:nth-child(2n + 4) {
    background-color: #cccccc;
}

#tertiary tr:hover {
    background-color: rgb(158, 156, 156)
}

#tertiary #table-images:hover {
    background-color: white;
}

@media only screen and (max-width: 900px) {
    #engineComparisonsTable table {
        transform: scale(.9);
    }
    #tertiary #engineComparisonsTable {
        width: 100%;
    }
}

@media only screen and (max-width: 700px) {
    #engineComparisonsTable table {
        transform: scale(.7);
    }
}

@media only screen and (max-width: 600px) {
    #engineComparisonsTable table {
        transform: scale(.5);
    }

    #tertiary #engineComparisonsTable {
        width: 200%;
    }
}



