#first {
    display: flex;
}

#backgroundVector {
    position: absolute;
    top: -1px;
    width: 100%;
    z-index: -100;
}

#leftFirst {
    height: 80vh;
    width: 45%;
    font-size: calc(1.5vw + 10px);
    margin: 0px;
    padding-top: 12%;
    padding-left: 12%;
    line-height: calc(5vw + 10px);
}

h1 {
    margin: 0px;
}

#rightFirst {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

img {
    vertical-align: middle;
    width: 70%;
}

#engineVector {
    position: absolute;
    width: 32%;
    vertical-align: middle;
    animation-duration: 5s;
    animation-name: transparent;
    animation-iteration-count: infinite;
}

@keyframes transparent {
    from {
      opacity: 80%;
    }
  
    50% {
      opacity: 0%;
    }

    to {
      opacity: 80%;
    }
  }

  @media only screen and (max-width: 1200px) {
    #rightFirst {
      padding-bottom: 10%;
    }
  }

  @media only screen and (max-width: 800px) {
    #rightFirst {
      padding-bottom: 20%;
    }
    #leftFirst {
      height: 60vh;
    }
  }

  @media only screen and (max-width: 600px) {
    #rightFirst {
      padding-bottom: 40%;
    }
  }