#secondary {
    padding-top: 1px;
    margin-top: 5%;
    background-color: #343a38;
    letter-spacing: .3px;
    line-height: 26px;
    position: relative;
    color: white;
}

#secondary:before {
    content: "";
    width: 100%;
    height: 200px;
    background-color: #343a38;
    position: absolute;
    top: -100px;
    left: 0;
    transform: skewY(4deg);
    z-index: -100;
  }

#secondary h2 {
    color: orange;
}

#secondaryWrapper {
    text-align: left;
    margin-left: 10%;
    margin-top: 10%;
    margin-right: 10%;
    font-size: 1.3rem;
}

#secondaryWrapper h2:nth-child(3) {
    padding-top: 5%;
    text-align: right;
}

#secondaryTextWrapper {
    display: flex;
}

#topLeftSecondary {
    margin-right: 50px;
}

#topRightSecondary {
    margin-left: 50px;
}


#secondaryImageWrapper {
    display: flex;
}

#responsiveTitle {
    display: none;
}

#bottomLeftSecondary img {
    width: 100%;
    position: relative;
    bottom: 30%;
    right: 10%;
}

#bottomRightSecondary {
    text-align: right;
    width: 100%;
    white-space: nowrap;
}

#imageGalleryButton {
    margin: 20%;
    background: orange;
    border: 1px solid orange;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
}

#imageGalleryButton:hover,
#imageGalleryButton:active {
  background-color: initial;
  background-position: 0 0;
  color: orange;
}

#imageGalleryButton:active {
  opacity: .5;
}

ul {
    font-size: 1.3rem;
    margin: 0px;
    padding: 0px;
    letter-spacing: .3px;
    line-height: 26px;
}

@media only screen and (max-width: 1200px) {
    #secondaryImageWrapper {
        display: block;
    }

    #normalTitle {
        display: none;
    }

    #responsiveTitle {
        display: block;
        text-align: right;
    }

    #bottomLeftSecondary img {
        width: 70%;
    }

    #bottomLeftSecondary {
        text-align: center;
    }
  }

@media only screen and (max-width: 800px) {
    #secondaryTextWrapper {
        display: block;
    }

    #bottomRightSecondary {
        white-space: normal;
    }
  }

