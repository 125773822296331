#header {
    background-color: #FFA80D;  
    line-height: 50px;
    display: flex;
    justify-content: space-around;
    white-space:nowrap;
}

#header div {
    display: flex;
}

#header a {
    text-decoration: none;
    color: black;
}

#header a:hover {
    text-decoration: underline;
}

#headerLeft {
    font-size: 22px;
    font-weight: bold;
    width: 50vw;
}

#headerLeft img {
    padding-right: 20px;
    height: 100%;
    width: 40px;
}

#headerRight div:hover {
    text-decoration: underline;
}

#headerRight div {
    padding-right: 20px;
}

